<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Connect And Authorize</h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> How to connect and authorize {{ integration | integrationFullname }}
  destination </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> {{ integration | integrationFullname }} Connection Details </h4>
<ol>
  <li class="text-muted mb-2"> The Azure storage name is 3 to 24 characters long, and can contain only lowercase letters
    and numbers. </li>
  <li class="text-muted mb-2">The connection string provides the credentials for authentication to Azure. Given the
    length of the string, carefully cut and paste the value into the form. The string will look like this:
    <code>DefaultEndpointsProtocol=https;AccountName=mydatalake;AccountKey=12ASJLKJ&#64;KLJQA1223122123+GFdtr4+SA33swr3211==;EndpointSuffix=core.windows.net</code>
  </li>
  <li class="text-muted mb-2">Did you get an error? Insufficient permission, incorrect names, or invalid settings
    provided during set up will
    cause the connection to Azure, and your data lake, to fail. Please double check your inputs and try again.</li>
</ol>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateTimeService, DATE_FORMAT_YYYY_MM_DD } from 'src/app/core/services/date-time.service';
import { FileState } from 'src/app/core/store/reducers/s3-upload.reducer';
import { errorWizardGeneralError } from 'src/app/shared/constants/flash-notifications';
import { CsvExportService } from 'src/app/shared/services/csv-export.service';
import { ModalErrorDetailsComponent } from '../../modals/modal-error-details/modal-error-details.component';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @Input() fileStates: FileState[] = [];
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Output() fileHashId = new EventEmitter<string>();

  bsModalRef: BsModalRef | null = null;
  private lastOpened: BsDropdownDirective | null = null;

  fileSizeUnit: number = 1024;

  constructor(
    protected modalService: BsModalService,
    private csvExportService: CsvExportService,
    private dateTimeService: DateTimeService
  ) { }

  ngOnInit(): void {
  }


  downloadCsvSchema(details): void {
    const headers = {
      name: 'name',
      type: 'type',
    };

    const csvData = [...details.verifyResponse.body.data.attributes.configuration.load.schema.fields];
    const filteredCsvData = csvData.map(item => ({
      name: item.name,
      type: item.type
    }));

    const formateString = DATE_FORMAT_YYYY_MM_DD + '_HH_mm_ss';
    const date = this.dateTimeService.format(Date.now(), formateString);
    const filename = details.file.name.replace('.csv', '');
    const fileTitle = filename + '_' + date;

    const exportableCsvData = this.csvExportService.createExportableArrayFromHeaders(headers, filteredCsvData);
    this.csvExportService.exportCSVFile(headers, exportableCsvData, fileTitle);
  }

  displayErrorDetails(details): void {
    let initialState = {};

    if (details.error.errors) {
      initialState = {
        details: details.error.errors
      };
    }
    else if (details.message) {
      initialState = {
        details: details.message
      };
    }
    else {
      initialState = {
        details: errorWizardGeneralError
      };
    }

    this.bsModalRef = this.modalService.show(ModalErrorDetailsComponent, { initialState });
  }

  removeFile(id: string): void {
    this.fileHashId.emit(id);
  }

  filterFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      }
      else if (fileSize < this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2));
      }
    }
    return fileSize;
  }

  filterUnitBasedOnFileSize(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      }
      else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      }
      else if (fileSize < this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'MB';
      }
    }

    return fileSizeInWords;
  }

  // prevent dropdown click loop
  clickDropdown(event: Event, dropdown: BsDropdownDirective): void {
    event.stopPropagation();

    if (!!this.lastOpened && this.lastOpened !== dropdown) {
      this.lastOpened.hide();
    }
    this.lastOpened = dropdown;
  }

}

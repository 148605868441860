import { Pipe, PipeTransform } from '@angular/core';
import { start } from 'repl';
import { DATE_FORMAT_YYYY_MM_DD, DateTimeService } from 'src/app/core/services/date-time.service';

@Pipe({
  name: 'calculateReportDateForJobs'
})
export class CalculateReportDateForJobsPipe implements PipeTransform {

  
  constructor(private dateTimeService: DateTimeService) {    
  }

  transform(date: string, startDateDeltaInDays: number): string {
    const convertDeltaInPositive = Math.abs(startDateDeltaInDays);
    const outPutDate = this.dateTimeService.subtractDays(date, convertDeltaInPositive, DATE_FORMAT_YYYY_MM_DD);
    
    return outPutDate;
  }
}

import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdAmzSellingPartnersOrders,
  productIdAmzSellingPartnersOrdersPii,
  productIdAmzSellingPartnersFinanceRt,
  productIdAmzSellingPartnersInbound,
  productIdAmzSellingPartnersSettlement,
  productIdAmzSellingBusRepFullfillment,
  productIdAmzSellingBusRepInventoryRt,
  productIdAmzSellingBusRepInventory,
  productIdAmzSellingBusRepSales,
  productIdAmzSellingBusRepFees,
  productIdAmzSellingPartnersSalesAndTraffic,
  productIdAmzSellingPartnersBrandAnalyticsReports,
  productIdAmzSellingPartnersInboundV2,
  productIdAmzSellingPartnerNotifications,
} from '../product-ids';


export const integrationAmzSellingPartnersOrders: Integration = {
  productId: productIdAmzSellingPartnersOrders,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Orders API',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Optimized for real-time sales data synchronization, order updates, and order research.',
  routerLink: '/wizards/amazon-selling-partner-orders',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_orders",
    "pathPrefix": "selling-partner/sales",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersOrdersPii: Integration = {
  productId: productIdAmzSellingPartnersOrdersPii,
  subProductId: "default",
  enabled: true,
  primary: true,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Orders API MFN',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Get real-time order data with PII for merchant-fulfilled transactions..',
  routerLink: '/wizards/amazon-selling-partner-orders-pii',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.4,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {},
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_orders_pii",
    "pathPrefix": "selling-partner/sales-pii",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersFinanceRt: Integration = {
  productId: productIdAmzSellingPartnersFinanceRt,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Finance Real-Time',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Real-time financial event data for refunds, orders, credits, shipments, and many more',
  routerLink: '/wizards/amazon-selling-partner-finance',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_finances",
    "pathPrefix": "selling-partner/finances",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersInbound: Integration = {
  productId: productIdAmzSellingPartnersInbound,
  subProductId: "default",
  enabled: false,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Inbound Fulfillment',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Inbound shipments data for inventory you send to the Amazon fulfillment networks.',
  routerLink: '/wizards/amazon-selling-partner-inbound-fulfillment',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.5,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: null
};

export const integrationAmzSellingPartnersInboundV2: Integration = {
  productId: productIdAmzSellingPartnersInboundV2,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  newBadge: true,
  brand: 'Amazon',
  name: 'Inbound Fulfillment (V2)',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Inbound shipments data for inventory you send to the Amazon fulfillment networks.',
  routerLink: '/wizards/amazon-selling-partner-inbound-fulfillment-v2',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.5,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_fulfillment_inbound",
    "pathPrefix": "selling-partner/fulfillment-inbound",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersSettlement: Integration = {
  productId: productIdAmzSellingPartnersSettlement,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Settlement Reports',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Amazon Pay reports provide detailed account activity for a settlement period',
  routerLink: '/wizards/amazon-selling-partner-settlement',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Bi-Weekly',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'pay.amazon.com/settlement',
    uri: 'https://pay.amazon.com/help/202070210'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_reports_settlement",
    pathPrefix: "selling-partner/reports-settlement",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzSellingBusRepFullfilment: Integration = {
  productId: productIdAmzSellingBusRepFullfillment,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Fulfillment',
  badge: 'none',
  type: 'source',
  sortOrder: 1400,
  tagLine: 'Fulfilled order, shipment, and item information with price, courier, and tracking data.',
  routerLink: '/wizards/amazon-selling-partner-fulfillment',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_reports_fulfillmentrt",
    "pathPrefix": "selling-partner/reports-fulfillmentrt",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingBusRepInventoryRt: Integration = {
  productId: productIdAmzSellingBusRepInventoryRt,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Inventory Real-Time',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Real-time data for available inventory, adjustments, statuses, age, fulfillment centers, and units.',
  routerLink: '/wizards/amazon-selling-partner-inventory-realtime',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.5,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    id: "59",
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_reports_inventoryrt",
    pathPrefix: "selling-partner/reports-inventoryrt",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzSellingBusRepInventory: Integration = {
  productId: productIdAmzSellingBusRepInventory,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Inventory',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Inventory metrics for status, fulfillment centers, adjustments, fees, issues, and transfers.',
  routerLink: '/wizards/amazon-selling-partner-inventory',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.74,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_reports_inventory",
    "pathPrefix": "selling-partner/reports-inventory",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingBusRepSales: Integration = {
  productId: productIdAmzSellingBusRepSales,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Sales Reports',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Sales data for both FBA and seller-fulfilled orders, returns, replacements, and removals.',
  routerLink: '/wizards/amazon-selling-partner-sales',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.74,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_reports_sales",
    pathPrefix: "selling-partner/reports-sales",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzSellingBusRepFees: Integration = {
  productId: productIdAmzSellingBusRepFees,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Fees',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Seller account level sales, storage, referral, fulfillment, and estimated fees',
  routerLink: '/wizards/amazon-selling-partner-fees',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_reports_fees",
    "pathPrefix": "selling-partner/reports-fees",
    "queryKey": "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersSalesAndTraffic: Integration = {
  productId: productIdAmzSellingPartnersSalesAndTraffic,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Sales & Traffic',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Performance metrics such as units, revenue, ASIN, sessions, page views, and buy box.',
  routerLink: '/wizards/amazon-selling-partner-sales-and-traffic',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_reports_retail",
    pathPrefix: "selling-partner/reports-retail",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersBrandAnalyticsReports: Integration = {
  productId: productIdAmzSellingPartnersBrandAnalyticsReports,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Seller Brand Analytics Reports',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Search terms for products that are winning the most clicks and conversions.',
  routerLink: '/wizards/amazon-selling-partner-brand-analytics-reports',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.6,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/docs/report-type-values#brand-analytics-reports'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_reports_brand",
    pathPrefix: "selling-partner/reports-brand",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzSellingPartnersNotifications: Integration = {
  productId: productIdAmzSellingPartnerNotifications,
  subProductId: "default",
  enabled: false,
  primary: true,  
  comingSoon: true,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Notifications',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Receive real-time notifications for sales, inventory, orders, and account updates directly from Amazon',
  routerLink: '/wizards/amazon-selling-partner-notifications',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity-type-select',
        'identity',
        'datasets',
        'aws',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_notifications",
    "pathPrefix": "selling-partner/notifications",
    "queryKey": "amazon-seller-central"
  }
};
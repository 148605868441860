import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const errorInvalidJobSchedule = 'Invalid job schedule';

@Pipe({
  name: 'convertCronExpression'
})
export class ConvertCronExpressionPipe implements PipeTransform {
  /*
      * * * * *
      | | | | |
      | | | | +-- Day of the week (0 - 6) (Sunday to Saturday)
      | | | +---- Month (1 - 12)
      | | +------ Day of the month (1 - 31)
      | +-------- Hour (0 - 23)
      +---------- Minute (0 - 59)


      * * * * *
      Every minute

      0 * * * *
      Every hour

      15 * * * *
      At 15 minutes past the hour

      * 2 * * *
      Every minute, between 02:00 AM and 02:59 AM

      15 2 * * *
      At 02:15 AM
  */

  transform(cronExpression: string, jobInfo: any): string {    
    const isPrimary = jobInfo.isPrimary;
    const reportDate = jobInfo.reportDate;
    const startDate = jobInfo.startDate;
    const status = jobInfo.status;
    const isHistory = (reportDate !== null); // if reportDate is not null then it is history job.
    const [minute, hour] = cronExpression.split(' ');
    let response = '';

    if(!isPrimary) {
      response = this.processForHistory(hour, minute, startDate, status);
    }
    else if(isPrimary) {
      response = this.processRecurring(hour, minute)
    }
    else {
      response = errorInvalidJobSchedule;
    }
    return response;
  }

  processForHistory(hour: string, minute: string, startDate: string, status: string): string {
    const formattedDate = dayjs(startDate).format("MMMM Do, YYYY");
    const period = +hour >= 12 ? 'PM' : 'AM';

    if(status === 'processed') {
      return `Ran at ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period} on ${formattedDate}`;
    }
    else if(status === 'active') {
      return `Scheduled to run at ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period} on ${formattedDate}`;
    }
    else {
      return errorInvalidJobSchedule;
    }
  }

  processRecurring(hour: string, minute: string): string {
    const recurringType = this.isJobHourlyOrDaily(hour);
    let response = null;
    const period = +hour >= 12 ? 'PM' : 'AM';
    
    // Per Devin, Jobs are either hourly or daily so these are the only two we care about
    // * * * * * should never be allowed and is an error condition, so we won't handle it.
    if(recurringType === 'hourly') {
      response = `Will run ${String(minute).padStart(2, '0')} minutes past the hour through the day`;
    }
    else if(recurringType === 'daily') {
      response = `Will run daily at ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
    }
    else {
      response = errorInvalidJobSchedule;
    }

    return response;
  }

  processFormattedDatetime(datetime: string) {
    return {
      date: dayjs(datetime).format("dddd, MMMM Do, YYYY"),
      time: dayjs(datetime).format("HH:mm")
    }
  }

  formatDate(year: number, month: number, day: number, hour: number, minute: number, second: number) {
    return `${year}-${String(month).padStart(2,'0')}-${String(day).padStart(2,'0')} ${String(hour).padStart(2,'0')}:${String(minute).padStart(2,'0')}:${String(second).padStart(2,'0')}`;
  }

  isJobHourlyOrDaily(hour: string): string {
    return (hour === '*') ? 'hourly' : 'daily'; 
  }

  getCurrentTimeUtc(): string {
    const today = new Date();
    return `${today.getUTCHours()}:${today.getUTCMinutes()}`;
  }

}
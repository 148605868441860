import { HistoryDefinition } from 'src/app/shared/interfaces/history.interfaces';
import { DateTimeService, DATE_FORMAT_YYYY_MM_DD } from 'src/app/core/services/date-time.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { flashNotificationsFormValidationErrorWithDetails } from 'src/app/core/store/actions/flash-notifications.actions';
import { AppState } from 'src/app/core/store/reducers';
import { errorWizardGeneralError } from 'src/app/shared/constants/flash-notifications';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  jobSubscription$: Subject<any> = new Subject<any>();
  jobRequestState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private http: HttpClient,
    private store$: Store<AppState>,
    private dateTimeService: DateTimeService
  ) { }

  async getJobs(
    subscriptionIds: number[],
    jobType: 'all' | 'primary' | 'history' = 'all',   // 'all', 'primary' and 'history' are valid values.
    startDate: string,
    endDate: string,
  ): Promise<void> {

    try {
      this.jobRequestState$.next({ state: 'job-request-initialize', payload: null });
      
      let responseData: any[] = [];
      
      // Base parameters
      const baseParams: any = {
        subscription_ids: subscriptionIds.join(','),
        order_by: '-is_primary,-id'
      };

      // Primary jobs parameters
      const primaryParams = {
        ...baseParams,
        status: 'active',
        is_primary: true,
      };

      // History jobs parameters
      const historyParams = {
        ...baseParams,
        is_primary: false,
        created_at__gte: startDate,
        created_at__lte: endDate,
      };

      if (jobType === 'primary') {
        // Fetch only active primary jobs without date filters
        responseData = await this.fetchJobs(primaryParams);
      }
      else if (jobType === 'history') {
        // Fetch only historical jobs with date filters
        responseData = await this.fetchJobs(historyParams);
      }
      else if (jobType === 'all') {
        // Fetch active primary jobs without date filters 
        responseData = await this.fetchJobs(primaryParams);
    
        // Fetch historical jobs with date filters and concat it with primary ones
        responseData = responseData.concat(await this.fetchJobs(historyParams));
      }

      this.jobSubscription$.next(responseData);
      this.jobRequestState$.next({ state: 'job-request-complete', payload: null });
    }
    catch (error) {
      this.jobRequestState$.next({ state: 'job-request-complete', payload: null });
      this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: errorWizardGeneralError, details: JSON.stringify(error) }));
      throw error;
    }
  }

  async getAllJobsForExport(
    subscriptionIds: number[],
  ): Promise<any> {
    try {     
      this.jobRequestState$.next({ state: 'job-request-initialize', payload: null });

      let responseData: any[] = [];
      
      // Base parameters
      const baseParams: any = {
        subscription_ids: subscriptionIds.join(','),
        order_by: '-is_primary,-id'
      };

      // Primary jobs parameters
      const primaryParams = {
        ...baseParams,
        status: 'active',
        is_primary: true,
      };

      // History jobs parameters
      const historyParams = {
        ...baseParams,
        is_primary: false
      };

      // Fetch active primary jobs without date filters 
      responseData = await this.fetchJobs(primaryParams, 150);

      // Fetch all historical jobs and concat it with primary ones
      responseData = responseData.concat(await this.fetchJobs(historyParams, 150));
        
      this.jobRequestState$.next({ state: 'job-request-complete', payload: null });
      return responseData;
    }
    catch (error) {
      this.jobRequestState$.next({ state: 'job-request-complete', payload: null });
      this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: errorWizardGeneralError, details: JSON.stringify(error) }));
      throw error;
    }
  }

  // Helper function for making API requests
  async fetchJobs (queryParams: any, requestPageSize?: number): Promise<any> {
    
    let hasMorePages: boolean = true;
    let pageNumber: number = 0;
    const pageSize: number = requestPageSize || 50; // change this to max supported value
    let responseData: any[] = [];
    
    while (hasMorePages) {
      pageNumber++;

      const params = {
        ...queryParams,
        page: pageNumber,
        page_size: pageSize,
      };

      const httpParams: HttpParams = new HttpParams({ fromObject: params });
      const jobRequestUrl = environment.openbridgeApiUris.service + '/service/jobs/jobs?' + httpParams.toString();
      const response: any = await this.getJobsFromUrl(jobRequestUrl);

      responseData = responseData.concat(response.data);

      hasMorePages = false;

      if (response.links.next !== "") {
        hasMorePages = true;
      }
    }

    return responseData;
  };

  async getJobsFromUrl(url: string): Promise<any> {
    const response = await this.http.get(url).toPromise();
    return response;
  }

  async getActiveHistoryJobs(subscriptionId): Promise<any> {
    this.jobRequestState$.next({ state: 'job-request-initialize', payload: null });

    const params = {
      subscription_ids: subscriptionId,
      is_primary: false,
      page: 1,
      status: 'active'
    };

    const httpParams: HttpParams = new HttpParams({ fromObject: params });

    const jobRequestUrl = environment.openbridgeApiUris.service +
      '/service/jobs/jobs?' + httpParams.toString();
    const response = await this.http.get(jobRequestUrl).toPromise();
    return response;
  }


  createExportableArrayFromHeaders(headers: any, dataArray: any[], productHistoryInformation: HistoryDefinition): any {
    const headerKeys = Object.keys(headers);
    const newData = [];

    for (const dataLine of dataArray) {
      const exportable = {};
      for (const key of headerKeys) {

        if (key === 'is_primary' && dataLine[key] === true) {
          exportable[key] = 'Recurring';
        } 
        else if(key === 'is_primary' && dataLine[key] === false) {
          exportable[key] = 'History';
        }
        else {
          exportable[key] = dataLine[key];
        }

      }
      newData.push(exportable);
    }

    return newData;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleErrorsService {

  constructor(
    protected httpClient: HttpClient,
    protected router: Router
  ) { }

  logError(errorDetails: any): void {

    if(typeof errorDetails === 'object') {
      errorDetails = ('toString' in errorDetails) ? errorDetails.toString() : JSON.stringify(errorDetails);
    }

    errorDetails = errorDetails.toString();
    const payload: ConsoleErrorPayload = this.generatePayload(errorDetails);
    this.httpClient.post(`${environment.openbridgeApiUris.account}/ce`, payload).toPromise();
    console.error('Error logged to server', errorDetails);
  }

  generatePayload(errorDetails: string): ConsoleErrorPayload {

    const errorPayload = {
      user_agent: navigator.userAgent,
      current_route: this.router.url,
      error_message: errorDetails
    }

    return {
      data: {
        type: 'ConsoleErrors',
        attributes: {
          error_details: errorPayload
        }  
      }
    };
  }
}

interface ConsoleErrorPayload {
  data: {
    type: string;
    attributes: {
      error_details: any;
    }
  }
}
export type FlashNotificationType = 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info' | 'light' | 'dark';
export type FlashNotificationClass = 'alert-primary' | 'alert-secondary' | 'alert-success'
  | 'alert-danger' | 'alert-warning' | 'alert-info' | 'alert-light' | 'alert-dark';

export class FlashNotification {

  class: FlashNotificationClass;
  details: string;
  message: string;
  type: FlashNotificationType;

  constructor(type: FlashNotificationType, message: string, details: string = '') {
    this.type = type;
    this.message = message;
    this.details = details;

    switch (this.type) {
      case 'primary':
        this.class = 'alert-primary';
        break;
      case 'secondary':
        this.class = 'alert-secondary';
        break;
      case 'success':
        this.class = 'alert-success';
        break;
      case 'error':
        this.class = 'alert-danger';
        break;
      case 'warning':
        this.class = 'alert-warning';
        break;
      case 'info':
        this.class = 'alert-info';
        break;
      case 'light':
        this.class = 'alert-light';
        break;
      case 'dark':
        this.class = 'alert-dark';
        break;
      default:
        break;
    }
  }

}
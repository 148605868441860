import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { subscriptionDelete, subscriptionUpdate } from '../store/actions/subscriptions.actions';

import { Subject, pipe } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { PipelineSubscription } from 'src/app/shared/models/pipeline-subscription.model';

import { IdentityService } from './identity.service';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { ApiStoragesService } from './api-storages.service';
import { fileInstandDownload } from 'src/app/core/functions/files';

import {
  productIdAmazonSponsoredAdsV2, productIdAmazonAttribution, productIdAwsAthena,
  productIdAwsRedshift, productIdAmazonDsp, productIdGoogleAds,
  productIdAwsRedshiftSpectrum, productIdAzureBlobStorage, productIdAzureDataLake,
  productIdGoogleBigquery, productIdSnowflakeWarehouse,
  productIdAmzSellingPartnersOrders, productIdAmzSellingPartnersOrdersPii, productIdAmzSellingPartnersFinanceRt,
  productIdAmzSellingPartnersInbound, productIdAmzSellingPartnersSettlement,
  productIdAmzSellingBusRepFullfillment, productIdAmzSellingBusRepInventoryRt,
  productIdAmzSellingBusRepInventory, productIdAmzSellingBusRepSales,
  productIdAmzSellingBusRepFees,
  productIdFacebookMarketing,
  productIdFacebookPageInsights,
  productIdInstagramInsights,
  productIdInstagramStories,
  productIdYoutubeCompetitorChannels,
  productIdYoutubeVideoInsights,
  productIdYoutubeCompetitorVideo,
  productIdYoutubeChannelInsights,
  productIdGoogleAnalytics360,
  productIdGoogleCampaignManager,
  productIdGoogleSearchAds360,
  productIdAmzSellingPartnersSalesAndTraffic,
  productIdShopifyInsights,
  productIdAmzSellingPartnersBrandAnalyticsReports,
  productIdAmzVendorsRetailAnalytics,
  productIdAmzVendorsRetailProcurmentOrder,
  productIdDataBricksExternal,
  productIdAmzVendorBrandAnalyticsReports,
  productIdStubProduct,
  productIdAmzVendorRealtimeAnalytics,
  productIdBatchSftpProcessing,
  productIdAmazonAdvertisingBrandMetrics,
  productIdAmazonAdvertisingMarketStream,
  productIdOpenbridgeAirbyte,
  productIdAmazonAdvertisingRecommendations,
  productIdAwsS3Source,
  productIdAmzPricingApi,
  productIdAmzCatalogApi,
  productIdAmazonBrandStoreInsights,
  productIdAmzSellingPartnersInboundV2,
  productIdAmazonDspV3,
  productIdAmzSellingPartnerNotifications,
  productIdAmazonAdvertisingMarketStreamV2
} from 'src/app/shared/constants/product-ids';

import { EncryptionService } from './encryption.service';
import { destinations } from 'src/app/shared/constants/integrations/destinations';
import { ObjectHashingService } from 'src/app/shared/services/object-hashing.service';
import { productIdAmazonSponsoredAdsV3 } from '../../shared/constants/product-ids';
import { DateTimeService } from './date-time.service';
import { TemplateProcessorService } from './template-processor.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  pipelineSaveState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>,
    private identityService: IdentityService,
    protected apiStorages: ApiStoragesService,
    private dateTimeService: DateTimeService,
    private intetegrationService: IntegrationService,
    private encryptionService: EncryptionService,
    private objectHashService: ObjectHashingService,
    private templateProcessorService: TemplateProcessorService,
    private cacheService: CacheService
  ) { }


  async process(accountId: number, userId: number, configState: any): Promise<any> {
    const hashArray = [];
    let httpResponse = null;

    try {

      const delay = ms => new Promise(res => setTimeout(res, ms));

      this.pipelineSaveState$.next({ state: 'pipeline-process-initialize', payload: null });

      await delay(2500);

      const payload: PipelineCreation = await this.generatePipelineConfig(accountId, userId, configState);

      // I hate that we need to get this again here
      const configStateKeys = Object.keys(configState.config);
      let integration = null;

      if(configStateKeys.includes('subProductId')) {
        integration = this.intetegrationService.findIntegrationFromIdAndSubproductId(payload.data.attributes.product, configState.config.subProductId);
      } else {
        integration = this.intetegrationService.findIntegrationFromId(payload.data.attributes.product);
      }

      if(!integration) {
        throw 'Integration not configured properly, please contact support.';
      }

      // tslint:disable-next-line: max-line-length
      payload.data.attributes.unique_hash = this.objectHashService.createHashArrayForIntegrationWithSpmData(integration, payload.data.attributes.subscription_product_meta_attributes);

      // Make sure it's a string.
      payload.data.attributes.unique_hash = JSON.stringify(payload.data.attributes.unique_hash);

      this.pipelineSaveState$.next({ state: 'pipeline-process-saving', payload: null });

      await delay(2500);

      const subscriptionPostUri = environment.openbridgeApiUris.subscription + '/sub';

      if (configState.config.wizardMode === 'edit') {
        
        if(this.intetegrationService.integrationIsDestination(payload.data.attributes.product)) {
          const storageGroupId = await this.getStorageGroupForPipeline(payload.data.id);
          if(payload.data.attributes.storage_group === null) {
            payload.data.attributes.storage_group = storageGroupId;
          }
        }

        httpResponse = await this.httpClient.patch(subscriptionPostUri + '/' + payload.data.id, payload,
          { observe: 'response' }).toPromise();
      }
      else if (configState.config.wizardMode === 'create') {
        httpResponse = await this.httpClient.post(subscriptionPostUri, payload,
          { observe: 'response' }).toPromise();
        }
      else {
        // tslint:disable-next-line: no-string-throw
        throw 'Unknown wizard mode ' + configState.config.wizardMode;
      }

      this.pipelineSaveState$.next({ state: 'pipeline-process-complete', payload: null });

      if (httpResponse.body.hasOwnProperty('status') && httpResponse.body['status'] === 'error') {
        this.pipelineSaveState$.next({ state: 'pipeline-process-complete', payload: null });
        throw httpResponse.body;
      }
      else {
        const transformedResponse = this.transformSubscriptionResponse(httpResponse.body['data'], httpResponse.body['included']);
        return transformedResponse;
      }

    } catch (error) {
      let stringError = null;

      if(typeof(error) === 'object') {
        stringError = JSON.stringify(error);
      }
      else if(typeof(error) === 'string') {
        stringError = error
      } else {
        stringError = "unknown error type thrown";
      }

      this.pipelineSaveState$.next({ state: 'pipeline-process-complete', payload: null });
      throw stringError;
    }
  }

  async patchPipeline(pipelineId: number, payload: any): Promise<any> {
    const response = await this.httpClient.patch(environment.openbridgeApiUris.subscription + '/sub/' + pipelineId, payload).toPromise();

    const transformedData = this.transformSubscriptionResponse(response['data'], response['included']);
    this.store$.dispatch(subscriptionUpdate({ pipelineSubscription: transformedData }));
    return response;
  }

  async cancelExistingSubscriptionKeyForSpNotification(remoteIdentityId: number): Promise<any> {
    const response = await this.httpClient.delete(`${environment.openbridgeApiUris.service}/service/sp/notifications/${remoteIdentityId}`).toPromise();
    return response;
  }

  async cancelAmazonAdvertisingMarketingStreamSQSSubscription(subscriptionId:number, remoteIdentityId: number): Promise<any> {
    const response = await this.httpClient.delete(`${environment.openbridgeApiUris.service}/service/amzadv/stream-v2/delete/${remoteIdentityId}/${subscriptionId}`).toPromise();
    return response;
  }

  // Pause a pipeline subscription based on subscription id.
  async pausePipelineSubscription(pipelineSubscriptionId: number): Promise<any> {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const payload = {
      data: {
        type: 'Subscription',
        id: pipelineSubscriptionId,
        attributes: {
          status: 'cancelled'
        }
      }
    };

    this.patchPipeline(pipelineSubscriptionId, payload);
  }

  async unpausePipelineSubscription(pipelineId: number): Promise<any> {
    try {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      this.apiStorages.storageTestState$.next({ state: 'pipeline-unpause-initialize', payload: null });

      const payload = {
        data: {
          type: 'Subscription',
          id: pipelineId,
          attributes: {
            status: 'active'
          }
        }
      };

      const response = this.patchPipeline(pipelineId, payload);

      this.apiStorages.storageTestState$.next({ state: 'pipeline-unpause-complete', payload: null });

    } catch (error) {
      this.apiStorages.storageTestState$.next({ state: 'pipeline-unpause-complete', payload: null });
      throw error;
    }
  }

  async deletePipelineSubscription(pipelineId: number): Promise<any> {
    try {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      this.apiStorages.storageTestState$.next({ state: 'pipeline-delete-initialize', payload: null });

      const payload = {
        data: {
          type: 'Subscription',
          id: pipelineId,
          attributes: {
            status: 'invalid'
          }
        }
      };

      const response = this.patchPipeline(pipelineId, payload);

      this.apiStorages.storageTestState$.next({ state: 'pipeline-delete-complete', payload: null });

    } catch (error) {
      this.apiStorages.storageTestState$.next({ state: 'pipeline-delete-complete', payload: null });
      throw error;
    }
  }

  async getAll(): Promise<any> {

    try {
      const pageSize = 100;
      const subscriptionsResponses = [];

      const firstPage = await this.requestSubscriptionPage(1, pageSize);
      const pages = firstPage['body'].meta.pagination.pages;
      subscriptionsResponses.push(firstPage.body);

      for (let index = 2; index <= pages; index++) {
        const requestedPage = await this.requestSubscriptionPage(index, pageSize);
        subscriptionsResponses.push(requestedPage.body);
      }

      return subscriptionsResponses;
    }
    catch (error) {
      throw error;
    }
  }

  async requestSubscriptionPage(page: number, pageSize: number): Promise<any> {
    const baseUri = environment.openbridgeApiUris.subscription + '/sub';
    const subscriptionGetUri = baseUri + '?sort=-created_at&page_size=' + pageSize + '&page=' + page;
    return await this.httpClient.get(subscriptionGetUri, { observe: 'response' }).toPromise();
  }

  appendArrayToArray(firstArray, secondArray): any[] {
    return [
      ...firstArray,
      secondArray
    ];
  }

  transformSubscriptionResponse(data: any, included?: any[]): any {
    // Create a base pipeline subscription.
    const pipelineSubscription: PipelineSubscription = {
      id: +data.id,
      name: data.attributes.name,
      canonicalName: data.attributes.canonical_name,
      accountId: +data.attributes.account_id,
      productId: data.attributes.product_id,
      status: data.attributes.status,
      storageGroupId: data.attributes.storage_group_id,
      createdAt: data.attributes.created_at,
      modifiedAt: data.attributes.modified_at,
      isStorage: false, // handled below
      premiumProduct: false, // data.attributes.product.is_premium,
      productName: null, // handled below
      productBrand: null, // handled below
      productBadge: null, // handled below
      remoteIdentityId: null, // handled below
      remoteIdentityName: '', // handled below
      storageGroupKeyName: null, // handled below
      storageGroupName: null, // handled below
      storageGroupProductId: null, // null as defaulthandled below
      uniqueHash: JSON.parse(data.attributes.unique_hash),
      multiStorageParentId: data.attributes.multi_storage_parent_id || null,
      subProductId: data.attributes.subproduct_id || "default",
      history: false,
      tmpKey: '' // added because we removed historyRequest key at the same time as adding a new key, so now we need to artificially inflate the key count due to cache concerns.  Delete in 2025, or next time we do a big change to this.
    };

    let integration = null;

    // Get the integration from the product ID.
    if(pipelineSubscription.subProductId && pipelineSubscription.subProductId !== "default") {
      integration = this.intetegrationService.findIntegrationFromIdAndSubproductId(pipelineSubscription.productId, pipelineSubscription.subProductId);
    }
    else {
      integration = this.intetegrationService.findIntegrationFromId(pipelineSubscription.productId);
    }

    // if Integration not found for given productId then not return those product
    if (integration) {

      // Set integration related values
      pipelineSubscription.productName = integration.name;
      pipelineSubscription.productBrand = integration.productBrand;
      pipelineSubscription.productBadge = integration.badge;
      pipelineSubscription.isStorage = (integration.type === 'destination') ? true : false;
      pipelineSubscription.premiumProduct = integration.premiumIntegration;

      const storageGroup = this.findIncluded(included, 'StorageGroup', data.attributes.storage_group_id);

      if (storageGroup) {
        pipelineSubscription.storageGroupName = storageGroup.attributes.name;
        pipelineSubscription.storageGroupKeyName = storageGroup.attributes.key_name;
        pipelineSubscription.storageGroupProductId = storageGroup.attributes.product_id;
      }

      if (data.attributes.remote_identity_id !== null) {
        const pipelineIdentity = this.identityService.getIdentityById(data.attributes.remote_identity_id);
        if (pipelineIdentity) {
          pipelineSubscription.remoteIdentityId = pipelineIdentity.id;
          pipelineSubscription.remoteIdentityName = pipelineIdentity.name;
        }
      }

      return pipelineSubscription;
    }

  }

  findStorageGroupSubscriptionByStorageGroupId(storageGroupId: number): any {

    let subscription: any = null;

    this.store$.select('subscriptions').pipe(take(1)).subscribe(response => {
      if (typeof response.subscriptions[Symbol.iterator] === 'function') {
        response.subscriptions.forEach(value => {
          if (value.isStorage && value.storageGroupId === storageGroupId) {
            subscription = value;
          }
        });
      }
    });

    if (subscription) {
      return subscription;
    }

    return null;
  }

  async generatePipelineConfig(accountId: number, userId: number, wizardState: any, hashMechanism: any = null): Promise<PipelineCreation> {

    let pipelineMeta: any[] = [];

    switch (wizardState.productId) {

      // Stub product first so it's not mixed with real products.
      case productIdStubProduct:
        throw 'Unable to save mocked product wizards to the database.';
        break;

      /**
       *  Place conditions for destinations alphebetally under here
       */
      case productIdAwsAthena:
        pipelineMeta = await this.generateAwsAthenaPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAwsRedshift:
        pipelineMeta = await this.generateAwsRedshiftPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAwsRedshiftSpectrum:
        pipelineMeta = await this.generateAwsSpectrumPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAzureBlobStorage:
        pipelineMeta = await this.generateAzureBlobStoragePipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAzureDataLake:
        pipelineMeta = await this.generateAzureDataLakePipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdDataBricksExternal:
        pipelineMeta = await this.generateDataBricksExternalPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdGoogleAnalytics360:
        pipelineMeta = await this.generateGoogleAnalytics360PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdGoogleBigquery:
        pipelineMeta = await this.generateGoogleBigQueryPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdSnowflakeWarehouse:
        pipelineMeta = await this.generateSnowflakePipelineMeta(wizardState.config, wizardState.productId);
        break;

      /**
       *  Place conditions for sources alphebetally under here
       */
      case productIdOpenbridgeAirbyte:
        pipelineMeta = await this.generateOpenbridgeAirbytePipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonSponsoredAdsV2:
        pipelineMeta = await this.generateAmazonAdvertisingPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonSponsoredAdsV3:
        pipelineMeta = await this.generateAmazonAdvertisingV3PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonAdvertisingBrandMetrics:
        pipelineMeta = await this.generateAmazonAdvertisingV3PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonBrandStoreInsights:
        pipelineMeta = await this.generateAmazonAdvertisingV3PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonAdvertisingRecommendations:
        pipelineMeta = await this.generateAmazonAdvertisingV3PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonAttribution:
        pipelineMeta = await this.generateAmazonAttributionPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonDsp:
        pipelineMeta = await this.generateAmazonDspPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonDspV3:
        pipelineMeta = await this.generateAmazonDspV3PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonAdvertisingMarketStream:
        pipelineMeta = await this.generateAmazonAdvertisingMarketStreamPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmazonAdvertisingMarketStreamV2:
        pipelineMeta = await this.generateAmazonAdvertisingMarketStreamV2PipelineMeta(wizardState.config, wizardState.productId);
        break;        

      case productIdAmzCatalogApi:
        pipelineMeta = await this.generateCatalogApiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzPricingApi:
        pipelineMeta = await this.generatePricingApiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersOrders:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersOrdersPii:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;
  
      case productIdAmzSellingPartnersFinanceRt:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersInbound:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersInboundV2:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersSettlement:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingBusRepFullfillment:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingBusRepInventoryRt:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingBusRepInventory:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingBusRepSales:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnerNotifications: 
        pipelineMeta = await this.generateSqNotificationsPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingBusRepFees:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdFacebookMarketing:
        pipelineMeta = await this.generateFacebookMarketingPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdFacebookPageInsights:
        pipelineMeta = await this.generateFacebookPageInsightsPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersSalesAndTraffic:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzSellingPartnersBrandAnalyticsReports:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzVendorsRetailAnalytics:
        pipelineMeta = await this.generateVendorCentralGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzVendorsRetailProcurmentOrder:
        pipelineMeta = await this.generateVendorCentralGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzVendorBrandAnalyticsReports:
        pipelineMeta = await this.generateVendorCentralGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAmzVendorRealtimeAnalytics:
        pipelineMeta = await this.generateVendorCentralGeneralPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdAwsS3Source:
        pipelineMeta = await this.generateAwsS3Source(wizardState.config, wizardState.productId);
        break;

      case productIdInstagramInsights:
        pipelineMeta = await this.generateInstagramCommonPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdInstagramStories:
        pipelineMeta = await this.generateInstagramCommonPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdGoogleAds:
        pipelineMeta = await this.generateGoogleAdsPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdGoogleCampaignManager:
        pipelineMeta = await this.generateGoogleCampaignManagerPipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdGoogleSearchAds360:
        pipelineMeta = await this.generateGoogleSearchAds360PipelineMeta(wizardState.config, wizardState.productId);
        break;

      case productIdBatchSftpProcessing:
        pipelineMeta = await this.generateOpenbridgeCsvBatchProcessingPipelineMeta(wizardState.config, wizardState.productId);
        const templateData = await this.templateProcessorService.generateBatchInstructions(wizardState.config);
        fileInstandDownload('csv-sftp-file-instructions.txt', templateData, 'text/plain;charset=utf-8;')
        break;

      case productIdShopifyInsights:
        pipelineMeta = await this.generateShopifyInsightsMeta(wizardState.config, wizardState.productId);
        break;

      case productIdYoutubeCompetitorVideo:
        pipelineMeta = await this.generateYoutubeMultiChannelMeta(wizardState.config, wizardState.productId);
        break;

      case productIdYoutubeVideoInsights:
        pipelineMeta = await this.generateYoutubeSingleChannelMeta(wizardState.config, wizardState.productId);
        break;

      case productIdYoutubeCompetitorChannels:
        pipelineMeta = await this.generateYoutubeMultiChannelMeta(wizardState.config, wizardState.productId);
        break;

      case productIdYoutubeChannelInsights:
        pipelineMeta = await this.generateYoutubeSingleChannelMeta(wizardState.config, wizardState.productId);
        break;

      default:
        // tslint:disable-next-line: no-string-throw
        throw 'Failed to build metadata for product';

    }

    // Make sure we don't forget anything.
    if(pipelineMeta.includes(null)) {
      throw "Error: Pipeline meta array contains null value";
    }

    if (pipelineMeta.length === 0) {
      // tslint:disable-next-line: no-string-throw
      throw 'Pipeline config contains no meta and is invalid';
    }

    const storageGroupId = (wizardState.config.destination) ? +wizardState.config.destination : null;

    const remoteIdentityId = (wizardState.config.remoteIdentityId) ? wizardState.config.remoteIdentityId : null;

    const pipeline = await this.generateBasicPipeline(wizardState.config.integrationName, pipelineMeta,
      accountId, userId, wizardState.productId, storageGroupId, remoteIdentityId, wizardState.config.subscriptionId);

    return pipeline;
  }

  pipelineSubscriptionIsDeactivatable(pipeline: any, pipelines: any[]): boolean {

    // If the pipeline isn't active, then it can't be deactivated again.
    if (pipeline.status !== 'active') {
      return false;
    }

    // If it's not a storage pipeline then we can deactivate it.
    if (!this.pipelineIsStorage(pipeline)) {
      return true;
    }

    const associatedPipelines = pipelines.filter(object => (object['storageGroupId'] === pipeline.storageGroupId && object['status'] === 'active' && object['id'] !== pipeline.id));

    if (associatedPipelines.length === 0) {
      return true;
    }

    return false;
  }

  pipelineIsStorage(pipeline: any): boolean {
    const destinationProduct = destinations.find(destination => pipeline.productId === destination.productId) || false;
    if (destinationProduct && pipeline.productId === destinationProduct['productId']) {
      return true;
    }
    return false;
  }

  // tslint:disable-next-line: max-line-length
  private async generateBasicPipeline(pipelineName: string, pipelineMeta: any[], accountId: number, userId: number, productId: number, storageGroupId?: number, remoteIdentityId?: number, subscriptionId?: number): Promise<PipelineCreation> {

    const dateStart = this.dateTimeService.utcDateTime();

    const pipeline: PipelineCreation = {
      data: {
        type: 'Subscription',
        attributes: {
          account: accountId,
          user: userId,
          product: productId,
          name: pipelineName,
          status: 'active',
          subscription_product_meta_attributes: pipelineMeta,
          quantity: 1,
          price: 0.00,
          auto_renew: 1,
          date_start: dateStart,
          date_end: dateStart,
          invalid_subscription: 0,
          rabbit_payload_successful: 0,
          stripe_subscription_id: ''
        }
      }
    };

    if (subscriptionId) {
      pipeline.data.id = subscriptionId;
    }

    pipeline.data.attributes.storage_group = null;
    if (storageGroupId != null) {
      pipeline.data.attributes.storage_group = storageGroupId;
    }

    if (remoteIdentityId != null) {
      pipeline.data.attributes.remote_identity = remoteIdentityId;
    }
    return pipeline;
  }

  /**
   *  Place functions for destinations alphebetally under here
   */
  private async generateAwsAthenaPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['awsRegion', 's3Bucket', 'awsDatabaseName', 'awsAccessKey', 'awsSecretKey'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      awsSecretKey: configState.awsSecretKey
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'awsRegion') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaRegion',
          data_value: configState.awsRegion,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 's3Bucket') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaBucket',
          data_value: configState.s3Bucket,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsDatabaseName') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaDatabase',
          data_value: configState.awsDatabaseName,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsAccessKey') {
        metaRow = {
          data_id: 0,
          data_key:
            'athenaAccessKeyId',
          data_value: configState.awsAccessKey,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsSecretKey') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaSecretAccessKey',
          data_value: encryptedResponse.data.attributes.awsSecretKey,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAwsRedshiftPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['dbname', 'host', 'password', 'port', 'username'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'host') {
        metaRow = {
          data_id: 0,
          data_key: 'hostname',
          data_value: configState.host,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'port') {
        metaRow = {
          data_id: 0,
          data_key: 'port',
          data_value: configState.port,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dbname') {
        metaRow = {
          data_id: 0,
          data_key: 'databaseName',
          data_value: configState.dbname,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'username') {
        metaRow = {
          data_id: 0,
          data_key: 'username',
          data_value: configState.username,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'password') {
        metaRow = {
          data_id: 0,
          data_key: 'password',
          data_value: encryptedResponse.data.attributes.password,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateAwsSpectrumPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = [
      'dbname', 'host', 'password', 'port', 'username',
      'awsRegion', 'awsBucket', 'spectrumDb', 'spectrumSchema', 'awsIamRoleArn',
      'awsAccessKey', 'awsSecretKey'
    ];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password,
      awsSecretKey: configState.awsSecretKey
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'host') {
        metaRow = {
          data_id: 0,
          data_key: 'hostname',
          data_value: configState.host,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'port') {
        metaRow = {
          data_id: 0,
          data_key: 'port',
          data_value: configState.port,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dbname') {
        metaRow = {
          data_id: 0,
          data_key: 'databaseName',
          data_value: configState.dbname,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'username') {
        metaRow = {
          data_id: 0,
          data_key: 'username',
          data_value: configState.username,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'password') {
        metaRow = {
          data_id: 0,
          data_key: 'password',
          data_value: encryptedResponse.data.attributes.password,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }
      else if (value === 'awsRegion') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumRegion',
          data_value: configState.awsRegion,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsBucket') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumBucket',
          data_value: configState.awsBucket,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'spectrumDb') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumDatabase',
          data_value: configState.spectrumDb,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsIamRoleArn') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumIamRole',
          data_value: configState.awsIamRoleArn,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsAccessKey') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumAccessKeyId',
          data_value: configState.awsAccessKey,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'awsSecretKey') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumSecretAccessKey',
          data_value: encryptedResponse.data.attributes.awsSecretKey,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAzureBlobStoragePipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['storageContainer', 'connectionString'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      connectionString: configState.connectionString
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'storageContainer') {
        metaRow = {
          data_id: 0,
          data_key: 'azureContainer',
          data_value: configState.storageContainer,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'connectionString') {
        metaRow = {
          data_id: 0,
          data_key: 'azureConnectionString',
          data_value: encryptedResponse.data.attributes.connectionString,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }
      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAzureDataLakePipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['storageContainer', 'connectionString'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      connectionString: configState.connectionString
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'storageContainer') {
        metaRow = {
          data_id: 0,
          data_key: 'azureContainer',
          data_value: configState.storageContainer,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'connectionString') {
        metaRow = {
          data_id: 0,
          data_key: 'azureConnectionString',
          data_value: encryptedResponse.data.attributes.connectionString,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateDataBricksExternalPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = [
      'dataBricksServerHostname', 'dataBricksHttpPath', 'dataBricksAccessToken',
      'dataBricksSchema', 'dataBricksCatalog', 'storageFormat',
      'connectionString', 'containerName', 'credentialsName',
      'bucketUri', 'pathPrefix', 'useClustering',
      'usePartitioning'
    ];

    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      accessToken: configState.dataBricksAccessToken,
      connectionString: configState.connectionString
    });

    metaKeys.forEach((value) => {

      if (value === 'dataBricksServerHostname') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_server_hostname',
          data_value: configState.dataBricksServerHostname,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dataBricksHttpPath') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_http_path',
          data_value: configState.dataBricksHttpPath,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dataBricksAccessToken') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_access_token',
          data_value: encryptedResponse.data.attributes.accessToken,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }
      else if (value === 'dataBricksSchema') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_schema',
          data_value: configState.dataBricksSchema,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dataBricksCatalog') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_catalog',
          data_value: configState.dataBricksCatalog,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'storageFormat') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_storage_format',
          data_value: configState.storageFormat,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'connectionString') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_adls_connection_string',
          data_value: encryptedResponse.data.attributes.connectionString,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }
      else if (value === 'containerName') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_adls_container',
          data_value: configState.containerName,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'credentialsName') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_credentials_name',
          data_value: (configState.credentialsName === null) ? '' : configState.credentialsName,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'bucketUri') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_bucket_uri',
          data_value: configState.bucketUri,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'pathPrefix') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_path_prefix',
          data_value: configState.pathPrefix || '',
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'useClustering') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_use_clustering',
          data_value: configState.useClustering.toString(),
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'usePartitioning') {
        metaRow = {
          data_id: 0,
          data_key: 'databricks_use_partitioning',
          data_value: configState.usePartitioning.toString(),
          data_format: 'STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateGoogleBigQueryPipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'project_id', 'dataset_id'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value) => {

      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'project_id') {
        metaRow = {
          data_id: 0,
          data_key: 'project_id',
          data_value: configState.projectId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dataset_id') {
        metaRow = {
          data_id: 0,
          data_key: 'dataset_id',
          data_value: configState.datasetId,
          data_format: 'STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateGoogleAnalytics360PipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'project_id', 'dataset_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value) => {

      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'project_id') {
        metaRow = {
          data_id: 0,
          data_key: 'project_id',
          data_value: configState.projectId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dataset_id') {
        metaRow = {
          data_id: 0,
          data_key: 'dataset_id',
          data_value: configState.datasetId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }      

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateGoogleCampaignManagerPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'dfa_profile_id', 'dfa_report_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dfa_profile_id') {
        metaRow = {
          data_id: 0,
          data_key: 'dfa_profile_id',
          data_value: configState.profileId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dfa_report_id') {
        metaRow = {
          data_id: 0,
          data_key: 'dfa_report_id',
          data_value: configState.reportId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateGoogleSearchAds360PipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'project_id', 'dataset_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value) => {

      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'advertiser_id') {
        metaRow = {
          data_id: 0,
          data_key: 'advertiser_id',
          data_value: configState.advertiserId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'agency_id') {
        metaRow = {
          data_id: 0,
          data_key: 'agency_id',
          data_value: configState.agencyId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateOpenbridgeCsvBatchProcessingPipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['userid', 'passwd', 'rules', 'no_headers', 'table_path', 'table_name', 'table_uuid', 'storage_group_key_name'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password
    });

    metaKeys.forEach((value) => {

      if (value === 'userid') {
        metaRow = {
          data_id: 0,
          data_key: 'userid',
          data_value: configState.username,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'passwd') {
        metaRow = {
          data_id: 0,
          data_key: 'passwd',
          data_value: encryptedResponse.data.attributes.password,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }
      else if (value === 'no_headers') {
        metaRow = {
          data_id: 0,
          data_key: 'no_headers',
          data_value: (configState.hasHeaders) ? 'false' : 'true',
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'rules') {
        metaRow = {
          data_id: 0,
          data_key: 'rules',
          data_value: configState.rules,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'table_name') {
        metaRow = {
          data_id: 0,
          data_key: 'table_name',
          data_value: configState.tableName,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'table_uuid') {
        metaRow = {
          data_id: 0,
          data_key: 'table_uuid',
          data_value: configState.tableUUID,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'storage_group_key_name') {
        metaRow = {
          data_id: 0,
          data_key: 'storage_group_key_name',
          data_value: configState.storageGroupKeyName,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'table_path') {

        const tableName =  configState.tableName + '_' + configState.tableUUID;
        const tablePath = configState.storageGroupKeyName + '/' +  configState.username + '/' + tableName;

        metaRow = {
          data_id: 0,
          data_key: 'table_path',
          data_value: tablePath,
          data_format: 'STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }


  private async generateSnowflakePipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['account', 'databaseName', 'warehouse', 'schema', 'username', 'password', 'useClustering'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'account') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeAccount',
          data_value: configState.account,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'databaseName') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeDatabase',
          data_value: configState.databaseName,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'warehouse') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeWarehouse',
          data_value: configState.warehouse,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'schema') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeSchema',
          data_value: configState.schema,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'username') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeUser',
          data_value: configState.username,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'password') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakePassword',
          data_value: encryptedResponse.data.attributes.password,
          data_format: 'ENCRYPTED_STRING',
          product: productId
        };
      }
      else if (value === 'useClustering') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflake_use_clustering',
          data_value: configState.useClustering.toString(),
          data_format: 'STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  /**
   *  Place functions for sources alphebetally under here
   */
  private async generateOpenbridgeAirbytePipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['src_airbyte_image', 'dst_airbyte_image', 'schedule', 'unique_id', 'aws_region', 'aws_iam_role_arn',  'active_tab', 'serialized_cron_form', 'bucket_name'];
    let metaRow: MetaRow = null;


    metaKeys.forEach((value, idx) => {

      if (value === 'src_airbyte_image') {
        metaRow = {
          data_id: 0,
          data_key: 'src_airbyte_image',
          data_value: configState.sourceAirbyteImage,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'dst_airbyte_image') {
        metaRow = {
          data_id: 0,
          data_key: 'dst_airbyte_image',
          data_value: configState.destinationAirbyteImage,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'schedule') {
        metaRow = {
          data_id: 0,
          data_key: 'schedule',
          data_value: configState.schedule,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'active_tab') {
        metaRow = {
          data_id: 0,
          data_key: 'active_tab',
          data_value: configState.activeTab,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'serialized_cron_form') {
        metaRow = {
          data_id: 0,
          data_key: 'serialized_cron_form',
          data_value: configState.serializedCronForm,
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'aws_region') {
        metaRow = {
          data_id: 0,
          data_key: 'aws_region',
          data_value: configState.awsRegion,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'aws_iam_role_arn') {
        metaRow = {
          data_id: 0,
          data_key: 'aws_iam_role_arn',
          data_value: configState.awsIamRoleArn,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'unique_id') {
        metaRow = {
          data_id: 0,
          data_key: 'unique_id',
          data_value: configState.uniqueId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'bucket_name') {
        metaRow = {
          data_id: 0,
          data_key: 'bucket_name',
          data_value: configState.bucketName,
          data_format: 'STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);

    });

    return metaArray;

  }

  private async generateAmazonAdvertisingPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile', 'marketplace', 'type', 'kdpState','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'marketplace') {
        metaRow = {
          data_id: 0,
          data_key: 'marketplace',
          data_value: configState.marketplace,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'type') {
        metaRow = {
          data_id: 0,
          data_key: 'type',
          data_value: configState.type,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'kdpState') {
        metaRow = {
          data_id: 0,
          data_key: 'kdp_state',
          data_value: configState.kdpState,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateAmazonAdvertisingV3PipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile', 'stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAmazonAdvertisingMarketStreamPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile', 'queue_urls', 'queue_amz_sub_ids', 'aws_iam_role_arn'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'aws_iam_role_arn') {
        metaRow = {
          data_id: 0,
          data_key: 'aws_iam_role_arn',
          data_value: configState.awsIamRoleArn,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'queue_urls') {
        metaRow = {
          data_id: 0,
          data_key: 'queue_urls',
          data_value: JSON.stringify(configState.queueUrls),
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'queue_amz_sub_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'queue_amz_sub_ids',
          data_value: JSON.stringify(configState.queueAmzSubIds),
          data_format: 'JSON',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAmazonAdvertisingMarketStreamV2PipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'advertiser_id', 'profile', 'profile_type', 'queue_urls', 'dataset_params', 'aws_iam_role_arn','selected_tables'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile_type') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_type',
          data_value: configState.profileType,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'advertiser_id') {
        metaRow = {
          data_id: 0,
          data_key: 'advertiser_id',
          data_value: configState.advertiserId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'aws_iam_role_arn') {
        metaRow = {
          data_id: 0,
          data_key: 'aws_iam_role_arn',
          data_value: configState.awsIamRoleArn,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'queue_urls') {
        metaRow = {
          data_id: 0,
          data_key: 'queue_urls',
          data_value: JSON.stringify(configState.queueUrls),
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'dataset_params') {
        metaRow = {
          data_id: 0,
          data_key: 'dataset_params',
          data_value: JSON.stringify(configState.datasetParams) || '{}',
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'selected_tables') {
        metaRow = {
          data_id: 0,
          data_key: 'selected_tables',
          data_value: JSON.stringify(configState.selectedTables),
          data_format: 'JSON',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }  

  private async generateAmazonAttributionPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAmazonDspPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile', 'accountId', 'managed', 'advertiserIds','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      } else if (value === 'accountId') {
        metaRow = {
          data_id: 0,
          data_key: 'account_id',
          data_value: configState.accountId,
          data_format: 'STRING',
          product: productId
        };
      } else if (value === 'managed') {
        metaRow = {
          data_id: 0,
          data_key: 'managed',
          data_value: (configState.managed === true) ? 'true' : 'false',
          data_format: 'STRING',
          product: productId
        };
      } else if (value === 'advertiserIds') {
        metaRow = {
          data_id: 0,
          data_key: 'advertiser_ids',
          data_value: JSON.stringify(configState.advertiserIds),
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAmazonDspV3PipelineMeta(configState: any, productId): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    let metaKeys: string[] = [];
    let metaRow: MetaRow | null = null;

    if(configState.managed === true) {
      metaKeys = ['remote_identity_id', 'accountId', 'managed', 'advertiserIds', 'stage_ids'];
    }
    else {
      metaKeys = ['remote_identity_id', 'profile', 'accountId', 'managed', 'advertiserIds','stage_ids'];
    }

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      } else if (value === 'accountId') {
        metaRow = {
          data_id: 0,
          data_key: 'account_id',
          data_value: configState.accountId,
          data_format: 'STRING',
          product: productId
        };
      } else if (value === 'managed') {
        metaRow = {
          data_id: 0,
          data_key: 'managed',
          data_value: (configState.managed === true) ? 'true' : 'false',
          data_format: 'STRING',
          product: productId
        };
      } else if (value === 'advertiserIds') {
        metaRow = {
          data_id: 0,
          data_key: 'advertiser_ids',
          data_value: JSON.stringify(configState.advertiserIds),
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generatePricingApiGeneralPipelineMeta(configState: any, productId: number): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id','asins','identity_type','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 'asins') {
        metaRow = {
          data_id: 0,
          data_key: 'asins',
          data_value: JSON.stringify(configState.asinList),
          data_format: 'JSON',
          product: productId
        };
      }
      if (value === 'identity_type') {
        metaRow = {
          data_id: 0,
          data_key: 'identity_type',
          data_value: 'seller',
          data_format: 'STRING',
          product: productId
        };
      }      
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      
      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateCatalogApiGeneralPipelineMeta(configState: any, productId: number): Promise<MetaRow[]> {

    const keys = Object.keys(configState);
    let metaArray = [];

    if(keys.includes('idList')) {
      metaArray = await this.generateCatalogApiIdsPipelineMeta(configState, productId);
    } else
    if(keys.includes('keywordList')) {
      metaArray = await this.generateCatalogApiKeywordPipelineMeta(configState, productId);
    }
    else {
      throw 'Invalid product config state';
    }

    return metaArray;
  }

  private async generateCatalogApiKeywordPipelineMeta(configState: any, productId: number): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id','keywords','identity_type', 'subproduct_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 'keywords') {
        metaRow = {
          data_id: 0,
          data_key: 'keywords',
          data_value: JSON.stringify(configState.keywordList),
          data_format: 'JSON',
          product: productId
        };
      }
      if (value === 'identity_type') {
        metaRow = {
          data_id: 0,
          data_key: 'identity_type',
          data_value: configState.identityType,
          data_format: 'STRING',
          product: productId
        };
      }      
      if (value === 'subproduct_id') {
        metaRow = {
          data_id: 0,
          data_key: 'subproduct_id',
          data_value: configState.subProductId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateCatalogApiIdsPipelineMeta(configState: any, productId: number): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'ids', 'id_type', 'identity_type' ,'subproduct_id', 'stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 'id_type') {
        metaRow = {
          data_id: 0,
          data_key: 'id_type',
          data_value: configState.productIdentifierType,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 'ids') {
        metaRow = {
          data_id: 0,
          data_key: 'ids',
          data_value: JSON.stringify(configState.idList),
          data_format: 'JSON',
          product: productId
        };
      }      if (value === 'identity_type') {
        metaRow = {
          data_id: 0,
          data_key: 'identity_type',
          data_value: configState.identityType,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 'subproduct_id') {
        metaRow = {
          data_id: 0,
          data_key: 'subproduct_id',
          data_value: configState.subProductId,
          data_format: 'STRING',
          product: productId
        };
      }   
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      
      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateSpapiGeneralPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      
      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateSqNotificationsPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id','sqs_queue_arn','sqs_queue_url','notification_subscriptions','identity_type'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'sqs_queue_arn') {
        metaRow = {
          data_id: 0,
          data_key: 'sqs_queue_arn',
          data_value: configState.sqsQueueArn,
          data_format: 'STRING',
          product: productId
        };
      }    
      else if (value === 'sqs_queue_url') {
        metaRow = {
          data_id: 0,
          data_key: 'sqs_queue_url',
          data_value: configState.sqsQueueUrl,
          data_format: 'STRING',
          product: productId
        };
      }    
      else if (value === 'identity_type') {
        metaRow = {
          data_id: 0,
          data_key: 'identity_type',
          data_value: configState.identityType,
          data_format: 'STRING',
          product: productId
        };
      }  
      else if (value === 'notification_subscriptions') {
        metaRow = {
          data_id: 0,
          data_key: 'notification_subscriptions',
          data_value: configState.notificationSubscriptions,
          data_format: 'JSON',
          product: productId
        };
      }
      
      metaArray.push(metaRow);
    });

    return metaArray;    
  }

  private async generateVendorCentralGeneralPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAwsS3Source(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['s3_region','s3_bucket','s3_prefix','table_names', 'table_prefix'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 's3_region') {
        metaRow = {
          data_id: 0,
          data_key: 's3_region',
          data_value: configState.awsRegion,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 's3_bucket') {
        metaRow = {
          data_id: 0,
          data_key: 's3_bucket',
          data_value: configState.awsBucket,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 's3_prefix') {
        metaRow = {
          data_id: 0,
          data_key: 's3_prefix',
          data_value: configState.awsS3Prefix,
          data_format: 'STRING',
          product: productId
        };
      }
      if (value === 'table_names') {
        metaRow = {
          data_id: 0,
          data_key: 'table_names',
          data_value: JSON.stringify(configState.tableNames),
          data_format: 'JSON',
          product: productId
        };
      }
      if (value === 'table_prefix') {
        metaRow = {
          data_id: 0,
          data_key: 'table_prefix',
          data_value: configState.tablePrefix,
          data_format: 'STRING',
          product: productId
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateFacebookMarketingPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'ad_account_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'ad_account_id') {
        metaRow = {
          data_id: 0,
          data_key: 'ad_account_id',
          data_value: configState.adAccountId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateFacebookPageInsightsPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'account_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'account_id') {
        metaRow = {
          data_id: 0,
          data_key: 'account_id',
          data_value: configState.pageAccountId,
          data_format: 'STRING',
          product: productId
        }
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      };

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateInstagramCommonPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'fb_account_id', 'account_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'fb_account_id') {
        metaRow = {
          data_id: 0,
          data_key: 'fb_account_id',
          data_value: configState.pageAccountId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'account_id') {
        metaRow = {
          data_id: 0,
          data_key: 'account_id',
          data_value: configState.instagramAccountId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateGoogleAdsPipelineMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'manager_customer_id', 'client_customer_id','stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'client_customer_id') {
        metaRow = {
          data_id: 0,
          data_key: 'client_customer_id',
          data_value: configState.customerId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'manager_customer_id') {
        metaRow = {
          data_id: 0,
          data_key: 'manager_customer_id',
          data_value: (configState.managerId != null) ? configState.managerId : configState.customerId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateShopifyInsightsMeta(configState: any, productId): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'shop_created_at', 'stage_ids'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'shop_created_at') {
        metaRow = {
          data_id: 0,
          data_key: 'shop_created_at',
          data_value: configState.shopCreatedAt,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateYoutubeSingleChannelMeta(configState: any, productId: any): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'channel_id', 'stage_ids'];

    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'channel_id') {
        metaRow = {
          data_id: 0,
          data_key: 'channel_id',
          data_value: configState.profile,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    
      

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateYoutubeMultiChannelMeta(configState: any, productId: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'channel_ids', 'stage_ids'];

    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          data_format: 'STRING',
          product: productId
        };
      }
      else if (value === 'channel_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'channel_ids',
          data_value: JSON.stringify(configState.channelIds),
          data_format: 'JSON',
          product: productId
        };
      }
      else if (value === 'stage_ids') {
        metaRow = {
          data_id: 0,
          data_key: 'stage_ids',
          data_value: JSON.stringify(configState.stageIds),
          data_format: 'JSON',
          product: productId
        };
      }    

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private findIncluded(includedArray: any[], relationType: string, relationId: number): any {
    try {
      if(includedArray === undefined) {
        return null;      
      }
  
      return includedArray.find(o => {
        if (relationId === null) {
          return null;
        }
        else {
          return (o.type === relationType && o.id === relationId.toString());
        }
      });
    }
    catch (error) {
      console.error(error);
    }
  }

  private async getStorageGroupForPipeline(subscriptionId: number): Promise<any> {
    const response = await this.httpClient.get(environment.openbridgeApiUris.subscription + '/sub' + '/' + subscriptionId).toPromise();
    return response['data'].attributes.storage_group_id;
  }
}

export interface PipelineCreation {
  data: {
    id?: number,
    type: 'Subscription';
    attributes: {
      account: number;
      product: number;
      product_plan?: number;
      remote_identity?: number;
      storage_group?: number;
      user: number;
      name: string,
      quantity: number,
      price: number,
      status?: string,
      auto_renew?: number,
      rabbit_payload_successful?: number,
      stripe_subscription_id?: string,
      primary_job_id?: number,
      pipeline?: string,
      date_start: string,
      date_end: string,
      created_at?: string,
      invalidated_at?: string,
      modified_at?: string,
      notified_at?: string,
      invalid_subscription?: number,
      canonical_name?: string,
      unique_hash?: string,
      subscription_product_meta_attributes: any[]
    }
  };
}

export interface Pipeline {
  data: {
    type: 'Subscription',
    attributes: {
      account: {
        type: 'Account',
        id: number
      },
      product: {
        type: 'Product',
        id: number
      },
      product_plan?: {
        type: 'ProductPlan',
        id: number
      },
      storage_group: {
        type: 'StorageGroup',
        id: number
      },
      user: {
        type: 'User',
        id: number
      },
      name: string,
      quantity: number,
      price: number,
      status: string,
      auto_renew: boolean,
      rabbit_payload_successful?: boolean,
      stripe_subscription_id?: string,
      primary_job_id?: number,
      pipeline?: string,
      created_at: string,
      invalidated_at: string | null,
      modified_at: string,
      notified_at: string,
      invalid_subscription?: number,
      canonical_name: string,
      subscription_product_meta_attributes?: any[]
    }
  };
}

interface MetaRow {
  data_id: number;
  data_key: string;
  data_value: string;
  data_format: string;
  product: number;
}
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const DATE_FORMAT_MMM_DD_YYYY = 'MMM DD, YYYY';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_MMM_DD = 'MMM-DD';
export const DATE_TIME_START_OF_DAY = DATE_FORMAT_YYYY_MM_DD + ' 00:00:00';
export const DATE_TIME_END_OF_DAY = DATE_FORMAT_YYYY_MM_DD + ' 23:59:59';
export const DATE_FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';


@Injectable({
  providedIn: 'root'
})

export class DateTimeService {

  constructor() { }

  format(date, newFormat: string, currentFormat?: string): any {
    if (currentFormat) {
      // dayjs(value, 'YYYY-MM-DD').format('MMM-DD')
      return dayjs(date, currentFormat).format(newFormat);
    }
    else {
      // dayjs(value).format('MMM-DD')
      return dayjs(date).format(newFormat);
    }
  }

  subtractDays(date, days, outputFormat: string): string {
    return dayjs(new Date(date)).subtract(days, 'day').format(outputFormat);
  }

  addDays(date, days, outputFormat: string): string {
    return dayjs(new Date(date)).add(days, 'day').format(outputFormat);
  }

  addMinutes(date, minutes, outputFormat: string): string {
    return dayjs(new Date(date)).add(minutes, 'minute').format(outputFormat);
  }

  convertTimeStampToDate(timeStampDate, format: string): string {
    return dayjs(timeStampDate * 1000).format(format);
  }

  convertToUtcDate(date, format: string): string {
    return dayjs(date).utc(true).format(format);
  }

  convertTimeStampToEpoch(date: string | number): number {
    return dayjs(date).unix();
  }

  utcDateTime(format: string = ''): string {
    if(format !== '') {
      return dayjs.utc().format(format);
    }
    return dayjs.utc().format();
  }

  addMinuteToUtcDateTime(minutes, format): string {
    if(format !== '') {
      return dayjs.utc().add(minutes, 'minutes').format(format);
    }
    return dayjs.utc().add(minutes, 'minutes').format();    
  }

  /**
   * Compare if two epoch dates fall on the same calendar day
   * @param epoch1 - First epoch timestamp (number)
   * @param epoch2 - Second epoch timestamp (number)
   * @returns boolean - True if both dates are the same day, otherwise false
   */
  compareDatesForSameDay(epoch1: number, epoch2: number): boolean {
    // Convert epoch to Day.js date
    const date1 = dayjs(epoch1);
    const date2 = dayjs(epoch2);

    return date1.isSame(date2, 'day'); // Compare by day granularity
  }

}
